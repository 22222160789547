import React, { FC } from 'react'
import { compact, find, first, includes, throttle } from 'lodash'

import { 
    usePracticeProviderDashboardWarningNotifierSubscription,
    useGlobalProviderDashboardWarningNotifierSubscription,
    useProviderDashboardWarningsQuery
} from '@docpace/manager-react-apollo' 
import {
    ProviderDashboardWarningFragment,
} from '@docpace/shared-graphql/fragments/providerDashboardWarningFragments.types'

interface UseProviderDashboardWarningNotifierProps {
    practiceId: string
    providerIds: string[]
    skip?: boolean
}

interface UseProviderDashboardWarningNotifierOutput {
    rssSiteMessage: string | undefined
    managerSiteMessage: string | undefined
    hideRssSiteDelays: boolean
}

export const useProviderDashboardWarningNotifierQuery = ({
    practiceId, skip = false, providerIds
}:UseProviderDashboardWarningNotifierProps): UseProviderDashboardWarningNotifierOutput => {

    const { data, refetch } = useProviderDashboardWarningsQuery({
        skip,
        variables: {
            practiceId
        }
    })

    const doRefetch = throttle(()=>{
        refetch()
    }, 5000)

    usePracticeProviderDashboardWarningNotifierSubscription({
        skip,
        onData: ({ data }) => {
            doRefetch()
        },
    })
    
    useGlobalProviderDashboardWarningNotifierSubscription({
        skip,
        onData: ({ data }) => {
            doRefetch()
        },
    })

    const warnings = compact(data?.providerDashboardWarnings?.nodes)
    const globalMessage = find<ProviderDashboardWarningFragment>(warnings, { practiceId: null })
    const practiceMessage = find<ProviderDashboardWarningFragment>(warnings, { practiceId, providerId: null })
    let providerMessage

    if(providerIds?.length){
        providerMessage = find(warnings, ({ providerId })=>{ return includes(providerIds, providerId) })
    }

    return {
        rssSiteMessage: first(compact([
            providerMessage?.rssSiteMessage, 
            practiceMessage?.rssSiteMessage, 
            globalMessage?.rssSiteMessage
        ])),
        managerSiteMessage: first(compact([
            providerMessage?.managerSiteMessage, 
            practiceMessage?.managerSiteMessage, 
            globalMessage?.managerSiteMessage
        ])),
        hideRssSiteDelays: first(compact([
            providerMessage?.hideRssSiteDelays, 
            practiceMessage?.hideRssSiteDelays, 
            globalMessage?.hideRssSiteDelays
        ])),
    }
}
