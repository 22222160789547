import { endOfDay, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz'
export const tzOffsets = [
    { offset: 'UTC−12:00', name: 'Etc/GMT+12', title: 'Baker Island' },
    { offset: 'UTC−11:00', name: 'Pacific/Pago_Pago', title: 'American Samoa' },
    { offset: 'UTC−10:00', name: 'Pacific/Honolulu', title: 'Hawaii' },
    { offset: 'UTC−09:00', name: 'America/Anchorage', title: 'Alaska' },
    { offset: 'UTC−08:00', name: 'America/Los_Angeles', title: 'Pacific Time' },
    { offset: 'UTC−07:00', name: 'America/Denver', title: 'Mountain Time' },
    { offset: 'UTC−06:00', name: 'America/Chicago', title: 'Central Time' },
    { offset: 'UTC−05:00', name: 'America/New_York', title: 'Eastern Time' },
    { offset: 'UTC−04:00', name: 'America/Santiago', title: 'Chile' },
    { offset: 'UTC−03:00', name: 'America/Argentina/Buenos_Aires', title: 'Argentina' },
    { offset: 'UTC−02:00', name: 'America/Noronha', title: 'Fernando de Noronha' },
    { offset: 'UTC−01:00', name: 'Atlantic/Azores', title: 'Azores' },
    { offset: 'UTC±00:00', name: 'Europe/London', title: 'United Kingdom (GMT/UTC)' },
    { offset: 'UTC+01:00', name: 'Europe/Paris', title: 'Central European Time (CET)' },
    { offset: 'UTC+02:00', name: 'Europe/Athens', title: 'Eastern European Time (EET)' },
    { offset: 'UTC+03:00', name: 'Europe/Moscow', title: 'Russia' },
    { offset: 'UTC+04:00', name: 'Asia/Dubai', title: 'UAE' },
    { offset: 'UTC+05:00', name: 'Asia/Karachi', title: 'Pakistan' },
    { offset: 'UTC+06:00', name: 'Asia/Almaty', title: 'Kazakhstan' },
    { offset: 'UTC+07:00', name: 'Asia/Bangkok', title: 'Thailand' },
    { offset: 'UTC+08:00', name: 'Asia/Shanghai', title: 'China' },
    { offset: 'UTC+09:00', name: 'Asia/Tokyo', title: 'Japan' },
    { offset: 'UTC+10:00', name: 'Australia/Sydney', title: 'Eastern Australia' },
    { offset: 'UTC+11:00', name: 'Pacific/Guadalcanal', title: 'Solomon Islands' },
    { offset: 'UTC+12:00', name: 'Pacific/Auckland', title: 'New Zealand' },
]

export function startOfDayInTimezone(date, timeZone) {
    // Convert the input date to the equivalent date in the specified time zone
    const zonedDate = toZonedTime(date, timeZone);
    
    // return the start of the day in that time zone
    return startOfDay(zonedDate);
}

export function endOfDayInTimezone(date, timeZone) {
    // Convert the input date to the equivalent date in the specified time zone
    const zonedDate = toZonedTime(date, timeZone);
    
    // return the end of the day in that time zone
    return endOfDay(zonedDate);
}


export function isValidTimeZone(tz: string) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        throw new Error('Time zones are not available in this environment');
    }

    try {
        Intl.DateTimeFormat(undefined, {timeZone: tz});
        return true;
    }
    catch (ex) {
        return false;
    }
}