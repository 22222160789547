import React, { FC } from 'react'

interface EmptyPageMessageProps {
    message: string
    messageLineTwo?: string
    id?: string
}

export const EmptyPageMessage: FC<EmptyPageMessageProps> = ({
    id = 'empty-page-message',
    message,
    messageLineTwo,
}) => {
    return (
        <div 
            id={id} 
            className="w-full flex flex-col justify-center items-center h-full"
        >
            <div className="w-40 h-40">
                <img
                    className="h-full w-auto opacity-40"
                    src={'/static/Group.png'}
                    alt="Empty Page Icon"
                />
            </div>
            <div
                className="text-center mt-4 mx-10 font-semibold"
                style={{ maxWidth: 700 }}
            >
                <label>{message}</label>
            </div>
            {messageLineTwo && (
                <div
                    className="text-center mt-1 mb-4 mx-10"
                    style={{ maxWidth: 700 }}
                >
                    <label>{messageLineTwo}</label>
                </div>
            )}
        </div>
    )
}
