import React, { FC } from 'react'
import clsx from 'clsx'

import { ProviderDelay } from '@docpace/reception-status-react-hooks'
import { isPortraitMode } from '@docpace/shared-react-utils/windowUtils'


interface ProviderStatusTableProps {
    providerDelays: ProviderDelay[]
    rowHeight: number
    hideDelays: boolean
}

export const ProviderStatusDataTable: FC<ProviderStatusTableProps> = ({
    providerDelays,
    rowHeight,
    hideDelays
}) => {
    const humanizeDelayTime = (delay: number | string) => {
        if (typeof delay === 'string') delay = parseInt(delay) 
        const delayInHours = Math.floor(delay / 60)
        if(delay <= 0) return 'On Time';
        return delayInHours > 0
            ? `~${delayInHours} hour${ (delayInHours > 1) ? 's' : ''} delayed`
            : `${delay} min delayed`
    }

    const isPortrait = isPortraitMode()
    const textAlignmentClassnames = 'flex items-center'
    const textClassnames = 'font-medium text-slate-700 dark:text-gray-100'
    const textSizeClassnames = isPortrait ?
        'sm:text-lg md:text-xl lg:text-3xl'
        : 'sm:text-xl md:text-2xl lg:text-4xl'
        
     
    return (
        <div className="divide-y">
            {providerDelays.map((provider: ProviderDelay, index) => {
                return (
                    <div
                        className="grid grid-cols-8 justify-between content-center"
                        key={index}
                        style={{ height: rowHeight - 4 }}
                    >
                        <div
                            className={clsx(
                                textAlignmentClassnames,
                                textClassnames,
                                textSizeClassnames,
                                isPortrait ? 'col-span-6' : 'col-span-5',
                                'pl-8 lg:pl-12 pr-2'
                                )}
                                >
                            {provider.displayName}
                        </div>
                        <div
                            data-testid={provider?.providerId}
                            className={clsx(
                                textAlignmentClassnames,
                                textClassnames,
                                textSizeClassnames,
                                isPortrait ? 'col-span-2' : 'col-span-3',
                            )}
                        >
                            { hideDelays ? '-' : humanizeDelayTime(
                                provider?.providerMinutesLate ?? 0
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
