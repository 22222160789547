import React from 'react'
import clsx from 'clsx'
import Interval from 'react-interval'

import { AutoSizer } from '@docpace/shared-react-components/AutoSizer'
import { EmptyPageMessage } from '@docpace/shared-react-components/EmptyPageMessage'
import {
    ReceptionStatusSiteHeader,
} from '@docpace/reception-status-react-components/ReceptionStatusSiteHeader'
import {
    ReceptionStatusPracticeHeader,
} from '@docpace/reception-status-react-components/ReceptionStatusPracticeHeader'
import {
    ProviderStatusTable,
} from '@docpace/reception-status-react-components/ProviderStatusTable'
import { useReceptionStatusBase } from '@docpace/reception-status-react-hooks'
import { isPortraitMode } from '@docpace/shared-react-utils/windowUtils'
import { useProviderDashboardWarningNotifierQuery } from '@docpace/manager-react-apollo'

export function Index() {
    const {
        isLoading: baseIsLoading,
        practice,
        providerIds,
        isTableDataInitialized,
        providerDelayTableData,
        lastUpdated,
        autoSwipeDelayTime,
        refetch,
        clearFetchError,
        fetchError,
        manager,
        admin
    } = useReceptionStatusBase({})

    const practiceId = practice?.practiceId

    const { rssSiteMessage, hideRssSiteDelays } = useProviderDashboardWarningNotifierQuery({ 
        practiceId, 
        skip: !practiceId,
        providerIds
    })
    
    const containerStyles = isPortraitMode() 
    ? 'p-2'
    : 'p-4 xl:px-8'

    return (baseIsLoading || !isTableDataInitialized) ? (
        <div className="h-screen">
            <EmptyPageMessage id='initializing-message' message={'Initializing practice...'} />
        </div>
    ) : (!admin && !manager?.canAccessReceptionStatusSite) ? <div className="h-screen">
        <EmptyPageMessage id='no-access-message' 
            message={'This account does not have access to the Reception Status Dashboard'}
            messageLineTwo={`Please contact your administrator.`}
         />
    </div> : (
        <div className="h-screen w-screen flex flex-col">
            <AutoSizer>
                {({ width, height }) => {
                    return <div
                        id='header'
                        style={{ height, width }}
                        className="flex flex-col"
                    >
                        { rssSiteMessage?.length > 0 && <div className='w-full flex justify-center py-2 px-10 text-2xl' style={{ backgroundColor: '#ff9966'}}>
                            { rssSiteMessage }
                        </div> }
                        <ReceptionStatusSiteHeader lastUpdated={lastUpdated} />
                        <ReceptionStatusPracticeHeader practice={practice} />
                        <div className={clsx(containerStyles, 'flex-1')}>
                            {providerDelayTableData && (
                                <ProviderStatusTable
                                    hideDelays={hideRssSiteDelays}
                                    providersDelay={providerDelayTableData}
                                    autoSwipeDelay={autoSwipeDelayTime}
                                />
                            )}
                        </div>
                    </div>
                }}
            </AutoSizer>
            <Interval
                enabled
                timeout={70 * 1000} // 1m10s (this just over a minute so that the dashboard also says "a minute ago" in addition to "under a minute ago" for more liveliness)
                callback={() => {
                    if (!fetchError) {
                        refetch().then(() => {})
                    }
                }}
            />
            <Interval
                enabled
                timeout={5 * 60 * 1000} // 5m
                callback={() => {
                    if (fetchError) {
                        console.log('clearing fetch error')
                        clearFetchError()
                    }
                }}
            />
        </div>
    )
}

export default Index
