import React, { FC, useState } from 'react'
import Carousel from 'nuka-carousel'
import clsx from 'clsx'
import { AutoSizer } from '@docpace/shared-react-components/AutoSizer'
import { times } from 'lodash'

import { ProviderStatusDataTable } from '../ProviderStatusDataTable'
import { ProviderDelay } from '@docpace/reception-status-react-hooks'

interface ProviderStatusTableProps {
    providersDelay: ProviderDelay[]
    autoSwipeDelay?: number
    hideDelays?: boolean
}
const Pagination: FC<{ count: number; active: number }> = ({
    count,
    active,
}) => {
    return count > 1 ? (
        <div className="slider-control-bottomcenter rounded-full bg-white min-w-6 h-8 mt-4">
            <div className="pagination space-x-2 p-2 min-w-1 h-1 rounded-full">
                {Array(count)
                    .fill(0)
                    .map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={clsx(
                                    'paging-item',
                                    index === active && 'paging-item-active'
                                )}
                            >
                                {' '}
                            </li>
                        )
                    })}
            </div>
        </div>
    ) : (
        <div />
    )
}

interface TableSizeProps {
    rowHeight: number
    headerHeight: number
    pagerHeight: number
}

const defaultTableSizeProps: TableSizeProps = {
    rowHeight: 64,
    headerHeight: 48,
    pagerHeight: 48,
}

const lgTableSizeProps: TableSizeProps = {
    rowHeight: 96,
    headerHeight: 54,
    pagerHeight: 48,
}

export const ProviderStatusTable: FC<ProviderStatusTableProps> = ({
    providersDelay,
    autoSwipeDelay = 7,
    hideDelays
}) => {
    const dataRowCount = providersDelay?.length
    const [activePage, setActivePage] = useState(0)
    const defaultRows = 4
    let tableSizeProps = defaultTableSizeProps

    return (
        <div className="h-full">
            <AutoSizer>
                {({ width, height }) => {
                    if (width > 900 - 32) {
                        tableSizeProps = lgTableSizeProps
                    }

                    const { rowHeight, headerHeight, pagerHeight } =
                        tableSizeProps

                    const maxRowsWithoutPager = Math.floor(
                        (height - headerHeight) / (rowHeight + 8)
                    )
                    const maxRowsWithPager = Math.floor(
                        (height - headerHeight - pagerHeight) / (rowHeight + 8)
                    )
                    const showPager = dataRowCount >= maxRowsWithoutPager

                    const displayRowsCount = Math.max(
                        1,
                        Math.min(
                            showPager ? maxRowsWithPager : maxRowsWithoutPager,
                            defaultRows
                        ),
                        Math.min(
                            showPager
                                ? maxRowsWithPager
                                : maxRowsWithoutPager ?? dataRowCount
                        )
                    )

                    const tablePageCount = Math.max(
                        Math.ceil(dataRowCount / displayRowsCount),
                        1
                    )
                    const headerClassNames =
                        'text-white text-lg md:text-xl lg:text-3xl font-normal text-opacity-95'

                    return (
                        <div style={{ width, height }} className="">
                            <div className="flex flex-col justify-center items-center h-full">
                                <div
                                    className="bg-white dark:bg-base-100 border dark:border-docpace-dark rounded-xl shadow-xl overflow-hidden max-w-5xl"
                                    style={{ width }}
                                >
                                    <div
                                        className="grid grid-cols-8 flex justify-between p-2 items-center bg-docpace dark:bg-docpace-dark"
                                        style={{ height: headerHeight }}
                                    >
                                        <div
                                            className={clsx(
                                                headerClassNames,
                                                'col-span-5 pl-6 lg:pl-10'
                                            )}
                                        >
                                            Provider Name
                                        </div>
                                        <div
                                            className={clsx(
                                                headerClassNames,
                                                'col-span-3'
                                            )}
                                        >
                                            Current Status
                                        </div>
                                    </div>
                                    <Carousel
                                        className="provider-swipe	"
                                        autoplay={showPager}
                                        autoplayInterval={autoSwipeDelay * 1000}
                                        wrapAround={true}
                                        withoutControls={true}
                                        beforeSlide={(index) => {
                                            setActivePage(
                                                (index + 1) % tablePageCount
                                            )
                                        }}
                                    >
                                        {providersDelay.length ? (
                                            times(tablePageCount, (i) => {
                                                const sliceStart =
                                                    displayRowsCount * i
                                                const sliceEnd =
                                                    sliceStart +
                                                    displayRowsCount
                                                return (
                                                    <div
                                                        className="slider"
                                                        key={i}
                                                    >
                                                        <ProviderStatusDataTable
                                                            hideDelays={hideDelays}
                                                            providerDelays={providersDelay?.slice(
                                                                sliceStart,
                                                                sliceEnd
                                                            )}
                                                            rowHeight={
                                                                rowHeight
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div
                                                className="slider flex items-center justify-center text-slate-500 dark:text-gray-100 text-lg md:text-2xl lg:text-3xl xl:text-5xl"
                                                style={{
                                                    height:
                                                        displayRowsCount *
                                                        rowHeight,
                                                    maxHeight: 4 * rowHeight,
                                                }}
                                            >
                                                No scheduled appointments left
                                            </div>
                                        )}
                                    </Carousel>
                                </div>
                                <Pagination
                                    active={activePage}
                                    count={tablePageCount}
                                />
                            </div>
                        </div>
                    )
                }}
            </AutoSizer>
        </div>
    )
}
