import { atom } from 'jotai'
import { atomEffect } from 'jotai-effect'

import { 
    format, 
    setMilliseconds, 
    setMinutes, 
    setSeconds, 
    startOfDay, 
    endOfDay,
    isSameSecond,
    isSameMinute,
    isSameHour,
} from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz';

import { atomWithReset } from 'jotai/utils'
import { endOfDayInTimezone, startOfDayInTimezone } from '@docpace/shared-ts-types';


export const ctxQueryDateObjectAtom = atomWithReset<Date | undefined>(undefined) // this is the client's query date
export const ctxQueryDateObjectStartOfDayAtom = atom((get)=>get(ctxQueryDateObjectAtom) ? startOfDay(get(ctxQueryDateObjectAtom)) : undefined)
export const ctxQueryDateObjectEndOfDayAtom = atom((get)=>get(ctxQueryDateObjectAtom) ? endOfDay(get(ctxQueryDateObjectAtom)) : undefined)

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const ctxBrowserTimezoneAtom = atomWithReset<string>(browserTimezone) // making this an atom so that we can set this externally for SSR

const startingTimezone = (typeof window !== 'undefined' ? window?.localStorage?.getItem('contextTimezone') : null) ?? browserTimezone
export const ctxTimezoneAtom = atomWithReset<string>(startingTimezone)

export const ctxIsTimezoneSameAsBrowserAtom = atom<boolean>((get)=>{
    return get(ctxTimezoneAtom) === get(ctxBrowserTimezoneAtom)
})
export const ctxTimezoneOffsetFromBrowserAtom = atom<number>((get)=>{
    return getTimezoneOffset(get(ctxTimezoneAtom)) - getTimezoneOffset(get(ctxBrowserTimezoneAtom));
})

export const _ctxDateObjectAtom = atom<Date>(new Date()) // this is the client's context date
export const ctxDateObjectAtom = atom(
    (get)=>get(_ctxDateObjectAtom),
    (get, set, value: Date)=>{
        if(!isSameSecond(value, get(_ctxDateObjectAtom))){
            set(_ctxDateObjectAtom, setMilliseconds(value, 0))
        }
    }
)
export const ctxDateObjectByTheSecondAtom = atom<Date>(new Date()) // this only ticks over every second
export const ctxDateObjectByTheMinuteAtom = atom<Date>(new Date()) // this only ticks over every minute
export const ctxDateObjectByTheHourAtom = atom<Date>(new Date()) // this only ticks over every hour
export const ctxDateObjectStartOfDayAtom = atom((get)=>startOfDayInTimezone(get(ctxDateObjectByTheHourAtom), get(ctxTimezoneAtom))) // this only ticks over every day
export const ctxDateObjectEndOfDayAtom = atom((get)=>endOfDayInTimezone(get(ctxDateObjectByTheHourAtom), get(ctxTimezoneAtom))) // this only ticks over every day

interface DayStartEndSet {
    dayStart: Date
    dateStart: Date
    dayEnd: Date
    dateEnd: Date
}

export const ctxDateStartEndOfDayWithQueryAtom = atom<DayStartEndSet>((get)=>({
    dayStart: get(ctxDateObjectStartOfDayAtom),
    dateStart: get(ctxDateObjectStartOfDayAtom),
    dayEnd: get(ctxDateObjectEndOfDayAtom),
    dateEnd: get(ctxDateObjectEndOfDayAtom)
}))


export const ctxQueryDateStartEndOfDayWithQueryAtom = atom<DayStartEndSet>((get)=>get(ctxQueryDateObjectAtom) ? {
    dayStart: get(ctxQueryDateObjectStartOfDayAtom),
    dateStart: get(ctxQueryDateObjectStartOfDayAtom),
    dayEnd: get(ctxQueryDateObjectEndOfDayAtom),
    dateEnd: get(ctxQueryDateObjectEndOfDayAtom)
} : get(ctxDateStartEndOfDayWithQueryAtom))

export const ctxDateObjectEffectsAtom = atomEffect((get, set)=>{
    const ctxDateObject = get(ctxDateObjectAtom)
    if(!isSameHour(ctxDateObject, get(ctxDateObjectByTheHourAtom))){
        set(ctxDateObjectByTheHourAtom, setMinutes(setSeconds(setMilliseconds(ctxDateObject, 0),0),0))
        set(ctxDateObjectByTheMinuteAtom, setSeconds(setMilliseconds(ctxDateObject, 0),0))
        set(ctxDateObjectByTheSecondAtom, setMilliseconds(ctxDateObject, 0))
    } else if(!isSameMinute(ctxDateObject, get(ctxDateObjectByTheMinuteAtom))){
        set(ctxDateObjectByTheMinuteAtom, setSeconds(setMilliseconds(ctxDateObject, 0),0))
        set(ctxDateObjectByTheSecondAtom, setMilliseconds(ctxDateObject, 0))
    } else if(!isSameSecond(ctxDateObject, get(ctxDateObjectByTheSecondAtom))){
        set(ctxDateObjectByTheSecondAtom, setMilliseconds(ctxDateObject, 0))
    }
})
